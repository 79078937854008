export enum RequestState {
	IDLE,
	PENDING,
	SUCCESS,
	ERROR,
}

export enum ContestStatus {
	Scheduled = "scheduled",
	MatchDay = "match_day",
	Complete = "resulted",
}

export enum SocialNetwork {
	Facebook,
	Twitter,
	Mobile,
}

export enum ShareType {
	General = "general",
	League = "league",
}

export enum Locale {
	EN_US = "en-US",
}

export enum Language {
	EN = "en",
}

export enum ModalType {
	ERROR,
	SUCCESS,
	WELCOME,
}
