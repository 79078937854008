import React from "react";
import {ModalError} from "views/components/modals/modal_error/modal_error.component";

export const GlobalModals: React.FC = () => {
	return (
		<React.Fragment>
			<ModalError />
		</React.Fragment>
	);
};
