import React from "react";
import styled from "@emotion/styled";
import {Container} from "views/components/common/container/container.component";
import {HeaderDesktopNav} from "views/components/header/header_desktop_nav.component";

const Root = styled.header`
	padding: 6px 0;
	background-color: var(--primary-maroon);
	min-height: 62px;
	display: flex;
	align-items: center;
`;

const StyledContainer = styled(Container)`
	flex: 1;
	max-width: 1440px;
	padding: 0 34px;
`;

export const HeaderDesktop: React.FC = () => {
	return (
		<Root>
			<StyledContainer>
				<HeaderDesktopNav />
			</StyledContainer>
		</Root>
	);
};
