import {action, makeAutoObservable, observable} from "mobx";
import {injectable} from "inversify";
import {ModalType} from "data/enums";
import type {IModalMessage, TContentModals} from "data/types/modals";

export type TModalArguments<T> =
	| [type: Exclude<ModalType, TContentModals>]
	| [type: TContentModals, content: IModalMessage | T];

export interface IModalsStore {
	showModal<T = null>(...args: TModalArguments<T>): void;
	hideModal(): void;
	get modal(): ModalType | null;
	get modalContent(): unknown | null;
}

@injectable()
export class ModalsStore implements IModalsStore {
	@observable _visibleModal: ModalType | null = null;
	@observable _modalContent: unknown | null = null;

	get modal(): ModalType | null {
		return this._visibleModal;
	}

	get modalContent(): unknown | null {
		return this._modalContent;
	}

	constructor() {
		makeAutoObservable(this);
	}

	@action hideModal(): void {
		this._visibleModal = null;
		this._modalContent = null;
	}

	@action showModal<T = null>(...args: TModalArguments<T>): void {
		const [modalType, content = null] = args;

		this._visibleModal = modalType as ModalType;
		this._modalContent = content;
	}
}
