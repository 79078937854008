import React, {lazy} from "react";
import {Route, Routes} from "react-router-dom";

import {retryFailLoad} from "data/utils";
import {NotAuthOnlyRoute, PrivateRoute} from "views/components/route";

const Home = lazy(retryFailLoad(() => import("views/pages/login/login.page")));
const Registration = lazy(
	retryFailLoad(() => import("views/pages/registration/registration.page"))
);
const NotFound = lazy(retryFailLoad(() => import("views/pages/not_found/not_found.page")));

const GameplayPage = lazy(retryFailLoad(() => import("views/pages/gameplay/gameplay.page")));
const TutorialPage = lazy(retryFailLoad(() => import("views/pages/tutorial/tutorial.page")));
const HelpPage = lazy(retryFailLoad(() => import("views/pages/help/help.page")));
const PrizesPage = lazy(retryFailLoad(() => import("views/pages/prizes/prizes.page")));

export const RootRoutes: React.FC = () => (
	<Routes>
		<Route element={<NotAuthOnlyRoute />}>
			<Route index element={<Home />} />
			<Route path="/registration" element={<Registration />} />
		</Route>
		<Route element={<PrivateRoute />}>
			<Route path="/gameplay" element={<GameplayPage />} />
			<Route path="/tutorial" element={<TutorialPage />} />
		</Route>
		<Route path="/prizes" element={<PrizesPage />} />

		<Route path="help/*" element={<HelpPage />} />

		<Route path="*" element={<NotFound />} />
	</Routes>
);

export default RootRoutes;
