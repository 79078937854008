import React, {Fragment} from "react";
import {observer} from "mobx-react";
import {useNavigate} from "react-router-dom";
import {useViewController} from "data/services/locator";
import {IPostmessageGateController} from "views/components/postmessage_gate/postmessage_gate.controller";
import {Bindings} from "data/constants/bindings";
import {Preloader} from "views/components/preloader";

export const PostMessageGate: React.FC<React.PropsWithChildren> = observer(({children}) => {
	const {isLoginSuccess, isLoginFailure} = useViewController<IPostmessageGateController>(
		Bindings.PostmessageGateController,
		{navigate: useNavigate()}
	);

	console.log(isLoginSuccess);

	if (!isLoginSuccess) {
		return <Preloader />;
	}

	if (isLoginFailure) {
		return null;
	}

	return <Fragment>{children}</Fragment>;
});
