import {action, makeAutoObservable, observable, runInAction} from "mobx";
import {ViewController} from "data/types/structure";
import {inject, injectable} from "inversify";
import {Bindings} from "data/constants/bindings";
import {ContestStatus, ModalType} from "data/enums";
import type {IConference, IContest, IQuestion, IWeekQuestions} from "data/types/types";
import {type IFlipCardStore} from "data/stores/flip_card/flip_card.store";
import {type IModalsStore} from "data/stores/modals/modals.store";
import {IAxiosApiErrorGeneral} from "data/types/modals";
import {getErrorMessageFromAxiosResponse} from "data/utils/helpers";
import {type ILocalizationStore} from "data/stores/localization/localization.store";
import {findLast} from "lodash";
import {createConnextraScriptTag} from "data/utils/connextra";
import type {IUserStore} from "data/stores/user/user.store";

export interface IGameplayController extends ViewController {
	get contests(): IContest[];

	get selectedContest(): IContest | undefined;

	get selectedConference(): IConference | undefined;

	get week(): IWeekQuestions | null;

	get revealedQuestion(): (IQuestion & {day: number}) | null;

	get showLoader(): boolean;

	get showScheduledTitle(): boolean;
	get showCompletedTitle(): boolean;

	readonly i18n: ILocalizationStore;

	handleRevealQuestion: (day: number) => Promise<void>;
	handleCloseRevealedQuestionModal: () => void;
	getRevealedStats: (week: IWeekQuestions) => string;
	handleChangeRound: (contextId: string | number) => void;
	isContestStatusPlaying: (status: string) => boolean;
}

@injectable()
export class GameplayController implements IGameplayController {
	constructor(
		@inject(Bindings.LocalizationStore) public readonly i18n: ILocalizationStore,
		@inject(Bindings.FlipCardStore) private _flipCardStore: IFlipCardStore,
		@inject(Bindings.ModalsStore) private _modalsStore: IModalsStore,
		@inject(Bindings.UserStore) private _userStore: IUserStore
	) {
		makeAutoObservable(this);
	}

	@observable private _revealedQuestion: (IQuestion & {day: number}) | null = null;
	@observable private _showLoader = false;

	get contests() {
		return this._flipCardStore.contests;
	}

	get week() {
		return this._flipCardStore.week;
	}

	get selectedContest() {
		return this._flipCardStore.selectedContest;
	}

	get selectedConference() {
		return this._flipCardStore.selectedConference;
	}

	get revealedQuestion() {
		return this._revealedQuestion;
	}

	get showLoader() {
		return this._showLoader;
	}

	get showScheduledTitle() {
		return Boolean(
			this.selectedContest &&
				(this.selectedContest?.status === ContestStatus.Scheduled ||
					this._flipCardStore.isContestStatusPlaying(this.selectedContest.status))
		);
	}

	get showCompletedTitle() {
		return this.selectedContest?.status === ContestStatus.Complete;
	}

	isContestStatusPlaying = (status: string) => {
		return this._flipCardStore.isContestStatusPlaying(status);
	};

	@action
	handleRevealQuestion = async (day: number) => {
		try {
			const question = await this._flipCardStore.getDayQuestion();
			await this._flipCardStore.updateSelectedContest(Number(this.selectedContest?.id));

			runInAction(() => {
				this._revealedQuestion = {
					...question,
					day,
				};
				createConnextraScriptTag("cardflip", this._userStore.user?.id);
			});
		} catch (e) {
			const err = e as IAxiosApiErrorGeneral;
			this._modalsStore.showModal(ModalType.ERROR, {
				message: getErrorMessageFromAxiosResponse(err),
			});
		}
	};

	@action
	handleCloseRevealedQuestionModal = () => {
		runInAction(() => {
			this._revealedQuestion = null;
		});
	};

	getRevealedStats = (week: IWeekQuestions) => {
		const questions = Object.values(week.questions);
		const correctAnswers = questions.filter((e) => e?.isCorrect).length;
		const total = questions.length;

		return `${correctAnswers}/${total}`;
	};

	@action
	handleChangeRound = async (contextId: string | number) => {
		try {
			this.setLoaderState(true);

			await this._flipCardStore.updateSelectedContest(Number(contextId));
		} catch (e) {
			const err = e as IAxiosApiErrorGeneral;
			this._modalsStore.showModal(ModalType.ERROR, {
				message: getErrorMessageFromAxiosResponse(err),
			});
		} finally {
			this.setLoaderState(false);
		}
	};

	@action
	setLoaderState(flag: boolean) {
		runInAction(() => {
			this._showLoader = flag;
		});
	}

	@action
	async init() {
		createConnextraScriptTag("Homepage", this._userStore.user?.id);
		try {
			this.setLoaderState(true);

			const [contests] = await Promise.all([
				this._flipCardStore.fetchContests(),
				this._flipCardStore.fetchConferences(),
			]);
			const activeRound = contests.find((e) =>
				this._flipCardStore.isContestStatusPlaying(e.status)
			);
			const matchDayRound = contests.find((e) => e.status === ContestStatus.MatchDay);
			const scheduledRound = contests.find((e) => e.status === ContestStatus.Scheduled);
			const lastContest = findLast(contests, (e) => e.status === ContestStatus.Complete);
			const contestId =
				activeRound?.id || matchDayRound?.id || scheduledRound?.id || lastContest?.id;

			if (!contestId) return;

			await this._flipCardStore.updateSelectedContest(Number(contestId));
		} catch (e) {
			const err = e as IAxiosApiErrorGeneral;
			this._modalsStore.showModal(ModalType.ERROR, {
				message: getErrorMessageFromAxiosResponse(err),
			});
		} finally {
			this.setLoaderState(false);
		}
	}
}
