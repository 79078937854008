import React from "react";
import {observer} from "mobx-react";
import {Divider, IconButton, Modal, Typography} from "@mui/material";
import styled from "@emotion/styled";
import {useViewController} from "data/services/locator";
import type {IModalErrorController} from "views/components/modals/modal_error/modal_error.controller";
import {Bindings} from "data/constants/bindings";
import WarningAmberRoundedIcon from "@mui/icons-material/WarningAmberRounded";
import CloseIcon from "@mui/icons-material/Close";
import {Exist} from "views/components/exist/exist.component";

const ModalContent = styled.div`
	position: fixed;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: center;
	outline: none;

	max-width: 600px;
	width: 100%;
	background: #333333;
	padding: 24px 16px;
	border-radius: 30px;
	text-align: center;
	color: var(--tertiary-white);

	@media screen and (max-width: 640px) {
		max-width: unset;
		left: 24px;
		right: 24px;
		width: auto;
		transform: translateY(-50%);
	}

	button {
		max-width: 300px;
	}
`;

const Header = styled.div`
	position: relative;
	width: 100%;
	padding-right: 50px;
	margin-bottom: 48px;
`;

const Title = styled(Typography)`
	margin-right: -50px;
`;

const CloseBlock = styled.div`
	position: absolute;
	top: -11px;
	right: 0;
	width: 100%;
	display: flex;
	justify-content: flex-end;
	align-items: center;
`;

const CloseButton = styled(IconButton)`
	color: var(--tertiary-white);
`;

const StyledDivider = styled(Divider)`
	width: 100%;
	height: 3px;
	margin: 16px 0;
	max-width: 177px;
	background: var(--secondary-red);
`;

const Icon = styled.div`
	width: 80px;

	svg {
		width: 100%;
		height: auto;
		fill: var(--tertiary-white);
	}
`;

export const ModalError: React.FC = observer(() => {
	const {isOpen, modalContent, i18n, close} = useViewController<IModalErrorController>(
		Bindings.ModalErrorController
	);
	return (
		<Modal open={isOpen}>
			<ModalContent>
				<Header>
					<Title variant="h2">
						{i18n.t(modalContent?.title ?? "modal.error.title", "Error")}
					</Title>

					<CloseBlock>
						<CloseButton onClick={close}>
							<CloseIcon />
						</CloseButton>
					</CloseBlock>
				</Header>

				<Icon>
					<WarningAmberRoundedIcon />
				</Icon>

				<StyledDivider />

				<Exist when={Boolean(modalContent?.message)}>
					<Typography variant="body1">{modalContent?.message}</Typography>
				</Exist>
			</ModalContent>
		</Modal>
	);
});
