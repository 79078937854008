import {action, makeAutoObservable, observable, runInAction} from "mobx";
import {inject, injectable} from "inversify";
import {ViewController} from "data/types/structure";
import {Bindings} from "data/constants/bindings";
import {type IUserStore} from "data/stores/user/user.store";
import {type ILocalizationStore} from "data/stores/localization/localization.store";

export interface IHeaderController extends ViewController {
	get isMobileMenuOpen(): boolean;
	get isAuthorized(): boolean;
	readonly i18n: ILocalizationStore;

	handleLogout: () => void;
	openMobileMenu: () => void;
	closeMobileMenu: () => void;
	handleClickLink: () => void;
}

@injectable()
export class HeaderController implements IHeaderController {
	constructor(
		@inject(Bindings.LocalizationStore) public readonly i18n: ILocalizationStore,
		@inject(Bindings.UserStore) private _userStore: IUserStore
	) {
		makeAutoObservable(this);
	}

	@observable private _mobileMenuOpen = false;

	get isAuthorized() {
		return this._userStore.isAuthorized;
	}

	get isMobileMenuOpen(): boolean {
		return this._mobileMenuOpen;
	}

	@action
	handleLogout = () => {
		runInAction(() => {
			if (this._mobileMenuOpen) {
				this.closeMobileMenu();
			}
		});

		void this._userStore.logout();
	};

	@action
	openMobileMenu = () => {
		runInAction(() => {
			this._mobileMenuOpen = true;
		});
	};

	@action
	closeMobileMenu = () => {
		runInAction(() => {
			this._mobileMenuOpen = false;
		});
	};

	@action
	handleClickLink = () => {
		setTimeout(() => {
			runInAction(() => {
				this._mobileMenuOpen = false;
			});
		}, 100);
	};
}
