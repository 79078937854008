import {ViewController} from "data/types/structure";
import {inject, injectable} from "inversify";
import type {ILocalizationStore} from "data/stores/localization/localization.store";
import type {IHttpClientService} from "data/services/http";
import {Locale} from "data/enums";
import {Bindings} from "data/constants/bindings";
import {makeAutoObservable, runInAction} from "mobx";
import {isString} from "lodash";
import type {IUserStore} from "data/stores/user/user.store";

export interface IBootstrapController extends ViewController {
	get isReady(): boolean;
}

interface IPostMessage {
	data: string;
	origin: string;
}

@injectable()
export class BootstrapController implements IBootstrapController {
	private _userLocale: Locale = navigator.language as Locale;
	private _isReady = false;
	private _isTokenReceived = false;

	constructor(
		@inject(Bindings.LocalizationStore) private _i18nStore: ILocalizationStore,
		@inject(Bindings.UserStore) private _userStore: IUserStore,
		@inject(Bindings.ApiHTTPClient) private _apiHTTPClient: IHttpClientService
	) {
		makeAutoObservable(this);
	}

	get isReady(): boolean {
		return this._isReady && this._isTokenReceived;
	}

	/**
	 * The method is to define a user's locale. It can be done by:
	 * 1) navigator.language
	 * 2) Site URL
	 * 3) Some JSON or API request settings
	 * 4) Whatever else
	 */
	private async defineLocale(): Promise<Locale> {
		this._userLocale = await Promise.resolve(Locale.EN_US);
		return this._userLocale;
	}

	private parseData(data: string) {
		try {
			// eslint-disable-next-line @typescript-eslint/no-unsafe-return
			return JSON.parse(data) || {};
		} catch (e) {
			console.error(e);
			return {};
		}
	}

	private isEventHasType(event: IPostMessage) {
		return event.data && isString(event.data) && event.data.includes('"type"');
	}

	private onReceiveMessage = (event: IPostMessage) => {
		if (!this.isEventHasType(event) && !this._isTokenReceived) {
			return;
		}
		const {payload, type} = this.parseData(event.data) as unknown as {
			type: string;
			payload: string;
		};
		console.log(this.parseData(event.data));

		if (type === "login" && payload) {
			this._userStore.setToken(payload);
			runInAction(() => {
				this._isTokenReceived = true;
			});
		}
	};

	dispose() {
		window.removeEventListener("message", this.onReceiveMessage);
	}

	async init() {
		if (window.gsxMessage) {
			this.onReceiveMessage(window.gsxMessage);
		}
		window.addEventListener("message", this.onReceiveMessage);

		try {
			await this._i18nStore.switchLocale({
				locale: Locale.EN_US,
			});
		} catch (_err) {
			// Show error message to a user
		}

		// Set locale that will be appended to each request
		this._apiHTTPClient.setLocale(this._i18nStore.lang);

		runInAction(() => {
			this._isReady = true;
		});
	}
}
