import {inject, injectable} from "inversify";
import {makeAutoObservable, observable} from "mobx";
import {ViewController} from "data/types/structure";
import {Bindings} from "data/constants/bindings";
import {type ILocalizationStore} from "data/stores/localization/localization.store";
import {ContestStatus} from "data/enums";
import type {IFlipCardStore} from "data/stores/flip_card/flip_card.store";
import type {IQuestion} from "data/types/types";
import {isNull} from "lodash";

interface IController {
	idx: number;
	contestStatus: ContestStatus;
	question: IQuestion | null;
}

export interface IGameplayFlipCardController extends ViewController<IController> {
	readonly i18n: ILocalizationStore;
	get day(): number;
	get isScheduledWeek(): boolean;
	get isMatchDay(): boolean;
	get isCompletedWeek(): boolean;
	get isPlayingWeek(): boolean;
	get isRevealDay(): boolean;
	get isRevealDayPassed(): boolean;
	get isRevealScheduled(): boolean;
	get isShowRevealedCard(): boolean;
	get isShowUnrevealedCard(): boolean;
	get isRevealed(): boolean;

	// isContestStatusPlaying(status: string): boolean
}

@injectable()
export class GameplayFlipCardController implements IGameplayFlipCardController {
	@observable _day = 0;
	@observable _contestStatus = ContestStatus.Scheduled;
	@observable _question: IQuestion | null = null;

	constructor(
		@inject(Bindings.LocalizationStore) public readonly i18n: ILocalizationStore,
		@inject(Bindings.FlipCardStore) private _flipCardStore: IFlipCardStore
	) {
		makeAutoObservable(this);
	}

	get day() {
		return this._day;
	}

	get isScheduledWeek() {
		return this._contestStatus === ContestStatus.Scheduled;
	}

	get isMatchDay() {
		return this._contestStatus === ContestStatus.MatchDay;
	}

	get isCompletedWeek() {
		return this._contestStatus === ContestStatus.Complete;
	}

	get isPlayingWeek() {
		return this._flipCardStore.isContestStatusPlaying(this._contestStatus);
	}
	get isRevealDay(): boolean {
		return this._flipCardStore.isRevealDay(this._contestStatus, this.day);
	}

	get isRevealDayPassed() {
		return this._flipCardStore.isRevealDayPassed(this._contestStatus, this.day);
	}

	get isRevealScheduled() {
		return (
			this.isScheduledWeek ||
			(this.isPlayingWeek && !this.isRevealDay && !this.isRevealDayPassed)
		);
	}

	get isShowRevealedCard() {
		return this.isNotScheduledContest && this.isRevealed;
	}

	get isShowUnrevealedCard() {
		return this.isRevealDay || (this.isRevealDayPassed && !this.isRevealed);
	}

	get isRevealed() {
		return !isNull(this._question);
	}

	private get isNotScheduledContest() {
		return this.isCompletedWeek || this.isMatchDay || this.isPlayingWeek;
	}

	private get isNotMatchDay() {
		return this.isScheduledWeek || this.isPlayingWeek || this.isCompletedWeek;
	}

	onChange({contestStatus, question}: IController) {
		if (contestStatus !== this._contestStatus) {
			this._contestStatus = contestStatus;
		}

		if (question !== this._question) {
			this._question = question;
		}
	}

	init({idx, contestStatus, question}: IController) {
		this._day = idx + 1;
		this._contestStatus = contestStatus;
		this._question = question;
	}
}
