const SCRIPT_URL = "https://us.connextra.com/dcs/tagController/tag/5a216ae3f6da/f2p_footballflip_";
export const createConnextraScriptTag = (tag: string, user_id?: number) => {
	const elementName = "script";
	const SCRIPT_ID = "connextra-tagging";
	document.getElementById(SCRIPT_ID)?.remove();
	const src = `${SCRIPT_URL}${tag}`;

	const url = new URL(src);

	if (user_id) {
		url.searchParams.append("AccountID", user_id.toString());
	}

	const script = document.createElement(elementName);
	script.id = SCRIPT_ID;
	script.src = url.toString();
	script.async = true;
	script.defer = true;

	const firstJS = document.getElementsByTagName(elementName)[0];
	firstJS.parentNode!.insertBefore(script, firstJS);

	return script;
};
