import {inject, injectable} from "inversify";
import {Bindings} from "data/constants/bindings";
import {IApiResponse, type IHttpClientService} from "data/services/http";
import {IQuestion, IWeekQuestions} from "data/types/types";
import {AxiosResponse} from "axios";

interface IWeekQuestionsResponse {
	week: IWeekQuestions;
}

export interface IFlipCardApiProvider {
	getWeekQuestions: (
		params?: object
	) => Promise<AxiosResponse<IApiResponse<IWeekQuestionsResponse>>>;
	getDayQuestion: (
		params?: object
	) => Promise<AxiosResponse<IApiResponse<{question: IQuestion}>>>;
}

@injectable()
export class FlipCardApiProvider {
	constructor(@inject(Bindings.ApiHTTPClient) private _http: IHttpClientService) {}

	getWeekQuestions = (params?: object) =>
		this._http.get<IApiResponse<IWeekQuestionsResponse>>(`flip_card/questions/week`, params);
	getDayQuestion = (params?: object) =>
		this._http.get<IApiResponse<{question: IQuestion}>>(`flip_card/questions/day`, params);
}
