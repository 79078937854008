import {IAxiosApiErrorGeneral, IAxiosErrorMessageObject} from "data/types/modals";

export function getErrorMessageFromAxiosResponse(event: IAxiosApiErrorGeneral) {
	if (!event) {
		return "";
	}

	if (!event.response?.data?.errors) {
		return event.message;
	}

	return Object.values(event.response?.data?.errors)
		.map((e: IAxiosErrorMessageObject) => e.message)
		.join(" \n");
}
