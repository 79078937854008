import styled from "@emotion/styled";

export const Container = styled.div`
	max-width: 1240px;
	padding: 0 20px;
	margin: 0 auto;
	display: flex;
	gap: 20px;
	&.no-flex {
		display: block;
	}
	@media screen and (max-width: 900px) {
		padding: 0;
		flex-direction: column;
	}
`;
