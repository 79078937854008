export const Bindings = {
	JSONProvider: Symbol("JSONProvider"),
	AuthApiProvider: Symbol("AuthApiProvider"),
	ApiHTTPClient: Symbol("ApiHTTPClient"),
	UserApiProvider: Symbol("UserApiProvider"),
	FlipCardApiProvider: Symbol("FlipCardApiProvider"),
	PasswordApiProvider: Symbol("PasswordApiProvider"),
	RankingsApiProvider: Symbol("RankingsApiProvider"),
	JsonHTTPClient: Symbol("JsonHTTPClient"),
	ContentJsonHTTPClient: Symbol("ContentJsonHTTPClient"),
	CountriesStore: Symbol("CountriesStore"),
	UserStore: Symbol("UserStore"),
	ModalsStore: Symbol("ModalsStore"),
	RankingsStore: Symbol("RankingsStore"),
	LocalizationStore: Symbol("LocalizationStore"),
	StaticContentStore: Symbol("StaticContentStore"),
	FlipCardStore: Symbol("FlipCardStore"),
	AuthController: Symbol("AuthController"),
	LoginController: Symbol("LoginController"),
	RegistrationController: Symbol("RegistrationController"),
	MyAccountController: Symbol("MyAccountController"),
	SecretGateController: Symbol("SecretGateController"),
	HelpController: Symbol("HelpController"),
	HelpListController: Symbol("HelpListController"),
	ContactUsController: Symbol("ContactUsController"),
	ChecksumStore: Symbol("ChecksumStore"),
	ForgotPasswordController: Symbol("ForgotPasswordController"),
	ResetPasswordController: Symbol("ResetPasswordController"),
	SessionController: Symbol("SessionController"),
	BootstrapController: Symbol("BootstrapController"),
	PostmessageGateController: Symbol("PostmessageGateController"),
	LocalizationController: Symbol("LocalizationController"),

	ModalErrorController: Symbol("ModalErrorController"),
	HeaderController: Symbol("HeaderController"),
	GameplayController: Symbol("GameplayController"),
	GameplayGameBarController: Symbol("GameplayGameBarController"),
	ModalWelcomeController: Symbol("ModalWelcomeController"),
	GameplayFlipCardController: Symbol("GameplayFlipCardController"),
	TutorialController: Symbol("TutorialController"),
	PrizesController: Symbol("PrizesController"),
	ModalRecoverUserController: Symbol("ModalRecoverUserController"),
};
