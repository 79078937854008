import {action, makeAutoObservable, observable, runInAction} from "mobx";
import {inject, injectable} from "inversify";
import {Bindings} from "data/constants/bindings";
import type {IAuthApiProvider, ILoginPayload} from "data/providers/api/auth.api.provider";
import type {
	IUserApiProvider,
	IRegistrationPayload,
	IUpdateUserPayload,
} from "data/providers/api/user.api.provider";

export interface IUser {
	id: number;
	email: string;
	username: string;
	tutorial: boolean;
	isNotificationsEnabled: boolean;
	isRecovered: boolean;
}

export interface IUserStore {
	get user(): IUser | undefined;
	get isAuthorized(): boolean;
	get wasLoggedOut(): boolean;
	get token(): string | null;

	register(payload: IRegistrationPayload): Promise<void>;
	update(payload: IUpdateUserPayload): Promise<void>;
	deactivate(): Promise<void>;
	login(payload: ILoginPayload): Promise<void>;
	logout(): Promise<void>;
	readTutorial(): Promise<void>;
	requestUser(): Promise<void>;
	setToken(token: string): void;
	recover(): Promise<void>;
}

@injectable()
export class UserStore implements IUserStore {
	@observable private _user?: IUser = undefined;
	@observable private _wasLoggedOut = false;
	@observable private _token: string | null = null;

	constructor(
		@inject(Bindings.AuthApiProvider) private _authApi: IAuthApiProvider,
		@inject(Bindings.UserApiProvider) private _userApi: IUserApiProvider
	) {
		makeAutoObservable(this);
	}

	get isAuthorized() {
		return Boolean(this.user);
	}

	get wasLoggedOut() {
		return this._wasLoggedOut;
	}

	get user() {
		return this._user;
	}

	get token() {
		return this._token;
	}

	setToken(token: string) {
		this._token = token;
	}

	@action
	async requestUser() {
		const response = await this._userApi.user();
		const {user} = response.data.success;

		runInAction(() => {
			this._user = user;
		});
	}

	@action
	async readTutorial() {
		await this._userApi.readTutorial();

		runInAction(() => {
			this._user!.tutorial = true;
		});
	}

	@action
	async login(payload: ILoginPayload) {
		const response = await this._authApi.login(payload);
		const {user} = response.data.success;

		runInAction(() => {
			this._user = user;
			this._wasLoggedOut = false;
		});
	}

	@action
	async register(payload: IRegistrationPayload) {
		const response = await this._authApi.register(payload);
		const {user} = response.data.success;

		runInAction(() => {
			this._user = user;
			this._wasLoggedOut = false;
		});
	}

	@action
	async update(payload: IUpdateUserPayload) {
		const response = await this._userApi.update(payload);
		const {user} = response.data.success;

		runInAction(() => {
			this._user = user;
		});
	}

	@action
	async logout() {
		await this._authApi.logout();

		runInAction(() => {
			this._user = undefined;
			this._wasLoggedOut = true;
		});
	}

	@action
	async deactivate() {
		await this._userApi.deactivateAccount();

		runInAction(() => {
			this._user = undefined;
			this._wasLoggedOut = true;
		});
	}

	@action
	async recover() {
		const response = await this._userApi.recover({terms: true});
		const {user} = response.data.success;

		runInAction(() => {
			this._user = user;
		});
	}
}
