import {ViewController} from "data/types/structure";
import {inject, injectable} from "inversify";
import type {IUserStore} from "data/stores/user/user.store";
import {action, makeAutoObservable, observable} from "mobx";
import {Bindings} from "data/constants/bindings";
import {RequestState} from "data/enums";
import {isEqual} from "lodash";
import type {ChangeEvent, SyntheticEvent} from "react";
import type {AxiosError} from "axios";
import type {IApiResponse} from "data/services/http";
import {extractErrorMessage} from "data/utils";
import type {ILocalizationStore} from "data/stores/localization/localization.store";
import {useNavigate} from "react-router-dom";
import {createConnextraScriptTag} from "data/utils/connextra";

interface IParams {
	token?: string | null;
	navigate: ReturnType<typeof useNavigate>;
}

interface IRegistrationFormElement extends HTMLFormElement {
	terms: HTMLInputElement;
}

export interface IRegistrationController extends ViewController<IParams> {
	readonly i18n: ILocalizationStore;

	get isFormDisabled(): boolean;
	get error(): Record<string, string> | null;

	handleFormSubmit: (event: SyntheticEvent<IRegistrationFormElement>) => void;
	handleFormOnChange: (event: ChangeEvent<IRegistrationFormElement>) => void;
}

@injectable()
export class RegistrationController implements IRegistrationController {
	@observable private _token: string | null = null;
	@observable private _requestState = RequestState.IDLE;
	@observable private _errorMsg: string | null = null;
	@observable private _errorPlace = "";

	get error() {
		if (!this._errorMsg) return null;

		return {
			[this._errorPlace || "common"]: this._errorMsg,
		};
	}

	get isFormDisabled(): boolean {
		return isEqual(this._requestState, RequestState.PENDING);
	}

	constructor(
		@inject(Bindings.UserStore) private _userStore: IUserStore,
		@inject(Bindings.LocalizationStore) public readonly i18n: ILocalizationStore
	) {
		makeAutoObservable(this);
	}

	@action private reportError(error: string, place: string = "") {
		this._errorMsg = error;
		this._errorPlace = place;

		return true;
	}

	@action public handleFormSubmit = (event: SyntheticEvent<IRegistrationFormElement>) => {
		event.preventDefault();

		const {terms} = event.currentTarget;

		const errorInvalidTerms = this.i18n.t(
			"registration.terms.error",
			"Please accept Terms & Conditions"
		);

		const validateList = [{field: terms, error: errorInvalidTerms, place: "terms"}];

		const hasError = validateList.find(({field, error, place}) =>
			field.checkValidity() ? false : this.reportError(error, place)
		);

		if (hasError || !this._token) {
			return;
		}

		const payload = {
			token: this._token,
			terms: terms.checked,
		};

		this._requestState = RequestState.PENDING;
		this._userStore
			.register(payload)
			.then(() => {
				createConnextraScriptTag("regconfirm", this._userStore.user?.id);
			})
			.catch(this.onError);
	};

	@action private onError = (error: AxiosError<IApiResponse>) => {
		this._errorMsg = extractErrorMessage(error);
		this._requestState = RequestState.ERROR;
	};

	@action handleFormOnChange = () => {
		this._errorMsg = null;
		this._errorPlace = "";
	};

	init(params: IParams) {
		if (!params.token) {
			params.navigate("/404", {replace: true});
			return;
		}
		createConnextraScriptTag("regstart");

		this._token = params.token;
	}
}
