import styled from "@emotion/styled";
import React from "react";
import {NavLink} from "react-router-dom";
import {observer} from "mobx-react";
import {useViewController} from "data/services/locator";
import {IHeaderController} from "views/components/header/header.controller";
import {Bindings} from "data/constants/bindings";
import {Exist} from "views/components/exist/exist.component";
import {PureButton} from "views/components/pure_button/pure_button.component";
import {IS_NOT_IFRAME} from "data/constants";

export const Root = styled.nav`
	display: flex;
	justify-content: space-between;
	align-items: center;
	flex-grow: 1;
`;

export const RootLeft = styled.div``;

export const RootRight = styled.div`
	margin-right: -12px;
`;

export const List = styled.ul`
	display: flex;
	align-items: center;
`;

export const Item = styled.li``;

export const StyledNavLink = styled(NavLink)`
	padding: 0 13px;

	font-size: 16px;
	font-weight: 400;
	line-height: 160%;
	color: var(--platform-background-white);

	&.active {
		pointer-events: none;
	}
`;

export const NavButton = styled(PureButton)`
	padding: 0 13px;

	font-size: 16px;
	font-weight: 400;
	line-height: 160%;
	color: var(--platform-background-white);
`;

export const HeaderDesktopNav: React.FC = observer(() => {
	const {isAuthorized, i18n, handleLogout} = useViewController<IHeaderController>(
		Bindings.HeaderController
	);

	return (
		<Root>
			<RootLeft>
				<List>
					<Exist when={isAuthorized}>
						<Item>
							<StyledNavLink to="/gameplay">
								{i18n.t("header.nav.gameplay", "Game Page")}
							</StyledNavLink>
						</Item>
					</Exist>
				</List>
			</RootLeft>
			<RootRight>
				<List>
					{/*<Item>*/}
					{/*	<StyledNavLink to="/help">*/}
					{/*		{i18n.t("header.nav.help", "Help")}*/}
					{/*	</StyledNavLink>*/}
					{/*</Item>*/}
					<Item>
						<StyledNavLink to="/help/faq's">
							{i18n.t("header.nav.faq", "FAQ's")}
						</StyledNavLink>
					</Item>
					<Item>
						<StyledNavLink to="/prizes">
							{i18n.t("header.nav.prizes", "Prizes")}
						</StyledNavLink>
					</Item>
					<Item>
						<StyledNavLink to="/tutorial">
							{i18n.t("header.nav.tutorial", "Tutorial")}
						</StyledNavLink>
					</Item>
					<Exist when={isAuthorized && IS_NOT_IFRAME}>
						<Item>
							<NavButton onClick={handleLogout}>
								{i18n.t("header.nav.logout", "Logout")}
							</NavButton>
						</Item>
					</Exist>
				</List>
			</RootRight>
		</Root>
	);
});
