/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable @typescript-eslint/no-empty-interface */
/* eslint-disable @typescript-eslint/no-unused-vars */

/*
 * Disabled the following rules:
 * @typescript-eslint/naming-convention
 * @typescript-eslint/no-empty-interface
 * @typescript-eslint/no-unused-vars
 * as MUI theme override required override interfaces
 *
 * More info on custom theming for Typography can be found here
 * https://mui.com/material-ui/customization/typography/#adding-amp-disabling-variants
 */
import {ThemeOptions} from "@mui/material/styles";

declare module "@mui/material/styles" {
	interface TypographyVariants {
		body3: React.CSSProperties;
		body4: React.CSSProperties;
		body9: React.CSSProperties;
		body10: React.CSSProperties;
	}

	// allow configuration using `createTheme`
	interface TypographyVariantsOptions {
		body3?: React.CSSProperties;
		body4?: React.CSSProperties;
		body9?: React.CSSProperties;
		body10?: React.CSSProperties;
	}
}

// Update the Typography's variant prop options
declare module "@mui/material/Typography" {
	interface TypographyPropsVariantOverrides {
		body3: true;
		body4: true;
		body9: true;
		body10: true;
	}
}

const fontFamilyBase = "var(--fontFamilyBase)";

export const typographyTheme: ThemeOptions = {
	typography: {
		fontFamily: fontFamilyBase,

		h1: {
			fontFamily: fontFamilyBase,
			fontWeight: 700,
		},
		h2: {
			fontFamily: fontFamilyBase,
			fontWeight: 800,
			fontSize: 16,
			lineHeight: "normal",
		},
		h3: {
			fontFamily: fontFamilyBase,
			fontWeight: 700,
			fontSize: "18px",
			lineHeight: "160%",
		},
		h4: {
			fontFamily: fontFamilyBase,
			fontWeight: 700,
		},
		h5: {
			fontFamily: fontFamilyBase,
			fontWeight: 700,
		},
		h6: {
			fontFamily: fontFamilyBase,
			fontWeight: 700,
		},
		body3: {
			fontFamily: fontFamilyBase,
			fontSize: "14px",
			lineHeight: "160%",
		},
		body4: {
			fontFamily: fontFamilyBase,
		},
		body9: {
			fontFamily: fontFamilyBase,
			fontSize: "16px",
			lineHeight: "160%",
		},
		body10: {
			fontFamily: fontFamilyBase,
			fontSize: "14px",
			lineHeight: "160%",
		},
	},
	components: {
		MuiTypography: {
			defaultProps: {
				variantMapping: {
					body3: "p", // Map the new variant to render a 'p' tag by default
					body4: "p", // Map the new variant to render a 'p' tag by default
					body9: "p", // Map the new variant to render a 'p' tag by default
					body10: "p", // Map the new variant to render a 'p' tag by default
				},
			},
		},
	},
};
