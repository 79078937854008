import React, {Fragment} from "react";
import {useIsMobile} from "data/hooks";
import {HeaderMobile} from "views/components/header/header_mobile";
import {HeaderDesktop} from "views/components/header/header_desktop.component";
import {Exist} from "views/components/exist/exist.component";

export const Header: React.FC = () => {
	const {isMobile} = useIsMobile();

	return (
		<Fragment>
			<Exist when={!isMobile}>
				<HeaderDesktop />
			</Exist>

			<Exist when={isMobile}>
				<HeaderMobile />
			</Exist>
		</Fragment>
	);
};
