import type {ComponentType} from "react";
import type {AxiosError} from "axios";
import type {IApiResponse} from "data/services/http";

type Factory<T> = () => Promise<{
	default: ComponentType<T>;
}>;

export function retryFailLoad<T>(fn: Factory<T>, retriesLeft = 5, interval = 1000): Factory<T> {
	return () =>
		new Promise((resolve, reject) => {
			fn()
				.then(resolve)
				.catch((error: unknown) => {
					setTimeout(() => {
						if (retriesLeft === 1) {
							reject(error);
							return;
						}

						retryFailLoad(fn, retriesLeft - 1, interval)().then(resolve, reject);
					}, interval);
				});
		});
}

export const extractErrorMessage = (error: AxiosError<IApiResponse>) =>
	error.response?.data?.errors?.[0]?.message || error.message;

export const copyToClipboard = async (content: string) => {
	try {
		await navigator.clipboard.writeText(content);
		return true;
	} catch (_err) {
		return false;
	}
};

export const ifNullShowDash = (val: string | null | number): string | null | number =>
	val === null ? "-" : val;

export * from "data/utils/countdown";
export * from "data/utils/socialShare";
