import {inject, injectable} from "inversify";
import {makeAutoObservable, observable} from "mobx";
import {useNavigate} from "react-router-dom";
import {ViewController} from "data/types/structure";
import {Bindings} from "data/constants/bindings";
import {type IFlipCardStore} from "data/stores/flip_card/flip_card.store";
import {type ILocalizationStore} from "data/stores/localization/localization.store";

export interface IGameplayGameBarController extends ViewController {
	get selectedContextId(): number | undefined;
	readonly i18n: ILocalizationStore;

	isContestStatusPlaying: (status: string) => boolean;
}

@injectable()
export class GameplayGameBarController implements IGameplayGameBarController {
	@observable _navigate: ReturnType<typeof useNavigate> | null = null;
	@observable _selectedRound: string = "";

	constructor(
		@inject(Bindings.FlipCardStore) private _flipCardStore: IFlipCardStore,
		@inject(Bindings.LocalizationStore) public readonly i18n: ILocalizationStore
	) {
		makeAutoObservable(this);
	}

	get selectedContextId() {
		return this._flipCardStore.selectedContest?.id;
	}

	isContestStatusPlaying = (status: string): boolean => {
		return this._flipCardStore.isContestStatusPlaying(status);
	};
}
