import {ViewController} from "data/types/structure";
import {action, makeAutoObservable, observable, runInAction} from "mobx";
import {useNavigate} from "react-router-dom";
import {inject, injectable} from "inversify";
import {type ILoginPayload} from "data/providers/api/auth.api.provider";
import {Bindings} from "data/constants/bindings";
import {type IUserStore} from "data/stores/user/user.store";
import {type ILocalizationStore} from "data/stores/localization/localization.store";
import {ModalType, RequestState} from "data/enums";
import {IAxiosApiErrorGeneral} from "data/types/modals";
import {getErrorMessageFromAxiosResponse} from "data/utils/helpers";
import {type IModalsStore} from "data/stores/modals/modals.store";
import {createConnextraScriptTag} from "data/utils/connextra";

interface IProps {
	navigate: ReturnType<typeof useNavigate>;
}

export interface IPostmessageGateController extends ViewController<IProps> {
	get isLoginLoading(): boolean;
	get isLoginSuccess(): boolean;
	get isLoginFailure(): boolean;
	get showChild(): boolean;
}

@injectable()
export class PostmessageGateController implements IPostmessageGateController {
	constructor(
		@inject(Bindings.UserStore) private _userStore: IUserStore,
		@inject(Bindings.LocalizationStore) public readonly i18n: ILocalizationStore,
		@inject(Bindings.ModalsStore) private readonly _modalsStore: IModalsStore
	) {
		makeAutoObservable(this);
	}

	private _navigate!: IProps["navigate"];
	@observable _requestState: RequestState = RequestState.IDLE;

	get isLoginLoading() {
		return this._requestState === RequestState.PENDING;
	}

	get isLoginSuccess() {
		return this._requestState === RequestState.SUCCESS;
	}

	get isLoginFailure() {
		return this._requestState === RequestState.ERROR;
	}

	get showChild() {
		return this.isLoginFailure || this.isLoginSuccess;
	}

	@action async login(payload: ILoginPayload) {
		runInAction(() => {
			this._requestState = RequestState.PENDING;
		});

		try {
			await this._userStore.logout();
		} catch (e) {
			console.error(e);
		}

		try {
			await this._userStore.login(payload);

			runInAction(() => {
				this._requestState = RequestState.SUCCESS;
				createConnextraScriptTag("loggedin", this._userStore.user?.id);
			});
		} catch (e) {
			const err = e as IAxiosApiErrorGeneral;
			if (err?.response?.status === 409) {
				runInAction(() => {
					this._requestState = RequestState.SUCCESS;
					this._navigate(`/registration?token=${payload.token}`, {replace: true});
				});
				return;
			}

			this._modalsStore.showModal(ModalType.ERROR, {
				message: getErrorMessageFromAxiosResponse(err),
			});

			runInAction(() => {
				this._requestState = RequestState.ERROR;
			});
		}
	}

	init(params: IProps) {
		this._navigate = params.navigate;

		if (this._userStore.token) {
			void this.login({token: this._userStore.token});
		}
	}
}
