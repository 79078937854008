import {action, makeAutoObservable} from "mobx";
import {inject, injectable} from "inversify";
import {Bindings} from "data/constants/bindings";
import {type IModalsStore} from "data/stores/modals/modals.store";
import {ModalType} from "data/enums";
import {ViewController} from "data/types/structure";
import {type IUserStore} from "data/stores/user/user.store";
import {type ILocalizationStore} from "data/stores/localization/localization.store";

export interface IModalWelcomeController extends ViewController {
	get showModal(): boolean;
	readonly i18n: ILocalizationStore;

	handleCloseModal: () => void;
}

@injectable()
export class ModalWelcomeController implements IModalWelcomeController {
	constructor(
		@inject(Bindings.LocalizationStore) public readonly i18n: ILocalizationStore,
		@inject(Bindings.ModalsStore) private _modalStore: IModalsStore,
		@inject(Bindings.UserStore) private _userStore: IUserStore
	) {
		makeAutoObservable(this);
	}

	get isNotUserReadTutorial() {
		return this._userStore.user?.tutorial === false;
	}

	get showModal() {
		return this._modalStore.modal === ModalType.WELCOME || this.isNotUserReadTutorial;
	}

	@action
	handleCloseModal = () => {
		if (this.isNotUserReadTutorial) {
			void this._userStore.readTutorial();
		}

		this._modalStore.hideModal();
	};
}
