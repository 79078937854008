import {inject, injectable} from "inversify";
import type {IHttpClientService} from "data/services/http";
import type {AxiosResponse} from "axios";
import {Bindings} from "data/constants/bindings";
import type {
	IArticles,
	ICategories,
	ISections,
} from "data/stores/static_content/static_content.store";
import {Language} from "data/enums";
import {IConference, type IContest} from "data/types/types";

export interface IJSONProvider {
	translations(locale: string): Promise<AxiosResponse<Record<string, unknown>>>;
	helpCategories(locale: string): Promise<AxiosResponse<ICategories>>;
	helpSections(locale: string): Promise<AxiosResponse<ISections>>;
	helpArticles(locale: string): Promise<AxiosResponse<IArticles>>;

	contests(): Promise<AxiosResponse<IContest[]>>;
	conferences(): Promise<AxiosResponse<IConference[]>>;
}

@injectable()
export class JSONProvider implements IJSONProvider {
	constructor(
		@inject(Bindings.JsonHTTPClient) private _jsonClient: IHttpClientService,
		@inject(Bindings.ContentJsonHTTPClient) private _contentJsonClient: IHttpClientService
	) {}

	translations = (locale: string) =>
		this._jsonClient.get<Record<string, unknown>>(`flip_card/loco/${locale}.json`);

	helpCategories = (locale: Language) =>
		this._contentJsonClient.get<ICategories>(`${locale}/categories.json`);
	helpSections = (locale: Language) =>
		this._contentJsonClient.get<ISections>(`${locale}/sections.json`);
	helpArticles = (locale: Language) =>
		this._contentJsonClient.get<IArticles>(`${locale}/articles.json`);

	contests = () => {
		return this._jsonClient.get<IContest[]>(`flip_card/contests.json`);
	};

	conferences = () => {
		return this._jsonClient.get<IConference[]>(`flip_card/conferences.json`);
	};
}
