import {makeAutoObservable} from "mobx";
import {ViewController} from "data/types/structure";
import {inject, injectable} from "inversify";
import {Bindings} from "data/constants/bindings";
import type {ILocalizationStore} from "data/stores/localization/localization.store";
import type {ISections, IStaticContentStore, TArticle} from "data/stores/static_content/static_content.store";
import {orderBy} from "lodash";

export interface IPrizesController extends ViewController {
	readonly i18n: ILocalizationStore;
	get contents(): TArticle[];
	get sections(): ISections["sections"];

	findArticleById: (sectionId: number) => TArticle[];
}

@injectable()
export class PrizesController implements IPrizesController {
	constructor(
		@inject(Bindings.LocalizationStore) public readonly i18n: ILocalizationStore,
		@inject(Bindings.StaticContentStore)
		private readonly _staticContentStore: IStaticContentStore
	) {
		makeAutoObservable(this);
	}

	public findArticleById = (sectionId: number) => {;
		const sections = this.contents?.filter((article) => article.section_id === sectionId) ?? [];

		return orderBy(sections, ["position"], ["asc"]);
	}

	get sections() {
		return this._staticContentStore.sections;
	}

	get contents() {
		return this._staticContentStore.articles;
	}

	init() {
		void this._staticContentStore.fetchStaticContent('prizes');
	}
}