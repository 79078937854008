import {Container, ContainerModule, type interfaces} from "inversify";
import {Bindings} from "data/constants/bindings";
import {type IUserStore, UserStore} from "data/stores/user/user.store";
import {AuthController, type IAuthController} from "views/controllers/auth/auth.controller";
import {type ILoginController, LoginController} from "views/pages/login/login.controller";
import {API_URL, CONTENT_JSON_URL, JSON_URL} from "data/constants";
import {type IJSONProvider, JSONProvider} from "data/providers/json/json.provider";
import {AuthApiProvider, type IAuthApiProvider} from "data/providers/api/auth.api.provider";
import {HttpClientFactory, type IHttpClientService} from "data/services/http";

import {
	type ISecretGateController,
	SecretGateController,
} from "views/controllers/secrete_gate/secret_gate.controller";
import {
	type ILocalizationStore,
	LocalizationStore,
} from "data/stores/localization/localization.store";
import {
	type IStaticContentStore,
	StaticContentStore,
} from "data/stores/static_content/static_content.store";
import {HelpController, type IHelpController} from "views/pages/help/help.controller";
import {
	HelpListController,
	type IHelpListController,
} from "views/components/help/help_list/help_list.controller";
import {
	ContactUsController,
	type IContactUsController,
} from "views/components/help/contact_us/contact_us.controller";
import {
	type IRegistrationController,
	RegistrationController,
} from "views/pages/registration/registration.controller";
import {type IUserApiProvider, UserApiProvider} from "data/providers/api/user.api.provider";
import {
	type ISessionController,
	SessionController,
} from "views/components/session/session.controller";
import {
	BootstrapController,
	type IBootstrapController,
} from "views/components/bootstrap/bootstrap.controller";
import {HeaderController, type IHeaderController} from "views/components/header/header.controller";
import {IModalsStore, ModalsStore} from "data/stores/modals/modals.store";
import {GameplayController, IGameplayController} from "views/pages/gameplay/gameplay.controller";
import {
	GameplayGameBarController,
	IGameplayGameBarController,
} from "views/components/gameplay/gameplay_gamebar/gameplay_game_bar.controller";
import {FlipCardStore, type IFlipCardStore} from "data/stores/flip_card/flip_card.store";
import {
	FlipCardApiProvider,
	type IFlipCardApiProvider,
} from "data/providers/api/flip_card.api.provider";
import {
	IModalErrorController,
	ModalErrorController,
} from "views/components/modals/modal_error/modal_error.controller";
import {
	type IModalWelcomeController,
	ModalWelcomeController,
} from "views/components/modals/modal_welcome/modal_welcome.controller";
import {
	IPostmessageGateController,
	PostmessageGateController,
} from "views/components/postmessage_gate/postmessage_gate.controller";
import {
	ILocalizationController,
	LocalizationController,
} from "views/controllers/localization/localization.controller";
import {
	GameplayFlipCardController,
	type IGameplayFlipCardController,
} from "views/components/gameplay/gameplay_flip_card/gameplay_flip_card.controller";
import {
	type ITutorialController,
	TutorialController,
} from "views/components/tutorial/tutorial.controller";
import {type IPrizesController, PrizesController} from "views/pages/prizes/prizes.controller";
import {
	type IModalRecoverUserController,
	ModalRecoverUserController,
} from "views/components/modals/modal_recover_user/modal_recover_user.controller";

export const DIContainer = new Container();

export const services = new ContainerModule((bind: interfaces.Bind) => {
	bind<IHttpClientService>(Bindings.ApiHTTPClient).toConstantValue(
		HttpClientFactory.createApiClient({
			baseURL: API_URL,
			withCredentials: true,
		})
	);

	bind<IHttpClientService>(Bindings.JsonHTTPClient).toConstantValue(
		HttpClientFactory.createJSONClient({
			baseURL: JSON_URL,
		})
	);

	bind<IHttpClientService>(Bindings.ContentJsonHTTPClient).toConstantValue(
		HttpClientFactory.createJSONClient({
			baseURL: CONTENT_JSON_URL,
		})
	);
});

export const providers = new ContainerModule((bind: interfaces.Bind) => {
	bind<IJSONProvider>(Bindings.JSONProvider).to(JSONProvider);
	bind<IAuthApiProvider>(Bindings.AuthApiProvider).to(AuthApiProvider);
	bind<IUserApiProvider>(Bindings.UserApiProvider).to(UserApiProvider);
	bind<IFlipCardApiProvider>(Bindings.FlipCardApiProvider).to(FlipCardApiProvider);
});

export const stores = new ContainerModule((bind: interfaces.Bind) => {
	bind<ILocalizationStore>(Bindings.LocalizationStore).to(LocalizationStore).inSingletonScope();
	bind<IUserStore>(Bindings.UserStore).to(UserStore).inSingletonScope();
	bind<IModalsStore>(Bindings.ModalsStore).to(ModalsStore).inSingletonScope();

	bind<IStaticContentStore>(Bindings.StaticContentStore).to(StaticContentStore);

	bind<IFlipCardStore>(Bindings.FlipCardStore).to(FlipCardStore).inSingletonScope();
});

export const controllers = new ContainerModule((bind: interfaces.Bind) => {
	bind<IAuthController>(Bindings.AuthController).to(AuthController);
	bind<ILoginController>(Bindings.LoginController).to(LoginController);
	bind<IRegistrationController>(Bindings.RegistrationController).to(RegistrationController);
	bind<ISecretGateController>(Bindings.SecretGateController).to(SecretGateController);
	bind<IHelpController>(Bindings.HelpController).to(HelpController);
	bind<IHelpListController>(Bindings.HelpListController).to(HelpListController);
	bind<IContactUsController>(Bindings.ContactUsController).to(ContactUsController);
	bind<ISessionController>(Bindings.SessionController).to(SessionController);
	bind<IBootstrapController>(Bindings.BootstrapController).to(BootstrapController);
	bind<IPostmessageGateController>(Bindings.PostmessageGateController).to(
		PostmessageGateController
	);
	bind<ILocalizationController>(Bindings.LocalizationController).to(LocalizationController);

	bind<IModalErrorController>(Bindings.ModalErrorController).to(ModalErrorController);
	bind<IHeaderController>(Bindings.HeaderController).to(HeaderController);
	bind<IGameplayController>(Bindings.GameplayController).to(GameplayController);
	bind<IGameplayGameBarController>(Bindings.GameplayGameBarController).to(
		GameplayGameBarController
	);
	bind<IModalWelcomeController>(Bindings.ModalWelcomeController).to(ModalWelcomeController);
	bind<IGameplayFlipCardController>(Bindings.GameplayFlipCardController).to(
		GameplayFlipCardController
	);
	bind<ITutorialController>(Bindings.TutorialController).to(TutorialController);
	bind<IPrizesController>(Bindings.PrizesController).to(PrizesController);
	bind<IModalRecoverUserController>(Bindings.ModalRecoverUserController).to(
		ModalRecoverUserController
	);
});
