import {inject, injectable} from "inversify";
import {action, makeAutoObservable, observable, runInAction} from "mobx";
import {Bindings} from "data/constants/bindings";
import {type IFlipCardApiProvider} from "data/providers/api/flip_card.api.provider";
import type {IContest, IQuestion, IWeekQuestions, IConference} from "data/types/types";
import {type IJSONProvider} from "data/providers/json/json.provider";
import {ContestStatus} from "data/enums";

export interface IFlipCardStore {
	get contests(): IContest[];
	get conferences(): IConference[];
	get selectedContest(): IContest | undefined;
	get selectedConference(): IConference | undefined;
	get week(): IWeekQuestions | null;

	fetchWeekQuestions: (contestId: number) => Promise<IWeekQuestions>;
	fetchContests: (params?: object) => Promise<IContest[]>;
	fetchConferences: (params?: object) => Promise<IConference[]>;
	getDayQuestion: () => Promise<IQuestion>;
	setSelectedContextId: (id: number) => void;
	updateSelectedContest: (id: number) => Promise<void>;
	isContestStatusPlaying(status: string): boolean;
	isRevealDay(status: string, day: number): boolean;
	isRevealDayPassed(status: string, day: number): boolean;
}

@injectable()
export class FlipCardStore implements IFlipCardStore {
	constructor(
		@inject(Bindings.FlipCardApiProvider) private _flipCardApiProvider: IFlipCardApiProvider,
		@inject(Bindings.JSONProvider) private _jsonProvider: IJSONProvider
	) {
		makeAutoObservable(this);
	}

	@observable private _contests: IContest[] = [];
	@observable private _conferences: IConference[] = [];
	@observable private _week: IWeekQuestions | null = null;
	@observable private _selectedContextId: number | null = null;

	get contests() {
		return this._contests;
	}

	get conferences() {
		return this._conferences;
	}

	get week() {
		return this._week;
	}

	get selectedContest() {
		return this.contests.find((contest) => contest.id === this._selectedContextId);
	}

	get selectedConference() {
		return this.conferences.find(
			(conference) => conference.label === this.selectedContest?.conference
		);
	}

	isContestStatusPlaying(status: string) {
		return /^day/.test(status);
	}

	isRevealDay(status: string, day: number): boolean {
		return this.isContestStatusPlaying(status) && status === `day${day}`;
	}

	isRevealDayPassed(status: string, day: number) {
		const [, contestPlayDay] = status.split("day");
		const isPlayingWeek = this.isContestStatusPlaying(status);

		return (
			(isPlayingWeek && !this.isRevealDay(status, day) && day < Number(contestPlayDay)) ||
			status === ContestStatus.Complete ||
			status === ContestStatus.MatchDay
		);
	}

	@action
	setSelectedContextId(id: number) {
		runInAction(() => {
			this._selectedContextId = id;
		});
	}

	@action
	async updateSelectedContest(contestId: number) {
		runInAction(() => {
			this.setSelectedContextId(contestId);
		});

		const resp = await this._flipCardApiProvider.getWeekQuestions({contest: contestId});

		runInAction(() => {
			this._week = resp.data.success.week;
		});
	}

	@action
	async fetchWeekQuestions(contestId: number) {
		const resp = await this._flipCardApiProvider.getWeekQuestions({contest: contestId});

		return resp.data.success.week;
	}

	@action
	async fetchContests() {
		const {data} = await this._jsonProvider.contests();

		runInAction(() => {
			this._contests = data;
		});

		return data;
	}

	@action
	async fetchConferences() {
		const {data} = await this._jsonProvider.conferences();

		runInAction(() => {
			this._conferences = data;
		});

		return data;
	}

	async getDayQuestion() {
		const resp = await this._flipCardApiProvider.getDayQuestion();
		return resp.data.success.question;
	}
}
