import React from "react";
import {observer} from "mobx-react";
import {NavLink} from "react-router-dom";
import styled from "@emotion/styled";
import {Box, Divider, Drawer, IconButton, List, ListItem} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import MenuIcon from "@mui/icons-material/Menu";
import {useViewController} from "data/services/locator";
import {IHeaderController} from "views/components/header/header.controller";
import {Bindings} from "data/constants/bindings";
import {Exist} from "views/components/exist/exist.component";
import {IS_NOT_IFRAME} from "data/constants";
import {PureButton} from "views/components/pure_button/pure_button.component";

const Root = styled.header`
	position: relative;
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 6px 16px;
	padding-left: 60px;
	background-color: var(--primary-maroon);
	min-height: 62px;
`;

const MenuBtn = styled(IconButton)`
	position: absolute;
	top: 50%;
	left: 18px;
	width: 34px;
	height: 34px;
	color: #fff;
	transform: translateY(-50%);

	svg {
		width: 34px;
		height: 34px;
	}
`;

const StyledList = styled(List)`
	padding: 0;
`;

const StyledListItem = styled(ListItem)`
	padding: 0;
	color: var(--tertiary-white);
	font-size: 16px;
	font-weight: 700;
	line-height: 160%;

	a {
		display: block;
		width: 100%;
		padding: 13px 16px;
		color: var(--tertiary-white);

		&.active {
			pointer-events: none;
		}
	}

	&.rest-links-start {
		margin-top: 8px;
	}

	&.rest-links-end {
		margin-bottom: 8px;
	}
`;

const StyledDivider = styled(Divider)`
	width: 100%;
	background: var(--secondary-pink);
`;

const NavButton = styled(PureButton)`
	width: 100%;
	padding: 13px 16px;
	color: var(--tertiary-white);
	font-size: 16px;
	font-weight: 700;
	line-height: 160%;
	text-align: left;
`;

export const HeaderMobile: React.FC = observer(() => {
	const {
		i18n,
		isMobileMenuOpen,
		isAuthorized,
		handleLogout,
		closeMobileMenu,
		openMobileMenu,
		handleClickLink,
	} = useViewController<IHeaderController>(Bindings.HeaderController);

	return (
		<Root>
			<MenuBtn onClick={openMobileMenu}>
				<MenuIcon />
			</MenuBtn>

			<Drawer
				anchor="left"
				open={isMobileMenuOpen}
				onClose={closeMobileMenu}
				sx={{
					".MuiBox-root": {
						background: "var(--primary-maroon)",
					},
					".MuiDrawer-paper": {
						padding: 0,
						width: "100%",
						borderRadius: 0,
						backgroundColor: "#000",
					},
				}}>
				<Box sx={{width: "100%"}}>
					<Box
						sx={{
							display: "flex",
							alignItems: "center",
							gap: "10px",
							background: "var(--primary-maroon)",
						}}>
						<IconButton onClick={closeMobileMenu}>
							<CloseIcon sx={{color: "#fff"}} />
						</IconButton>
					</Box>

					<Box>
						<StyledList>
							<StyledListItem>
								<StyledDivider />
							</StyledListItem>
							<Exist when={isAuthorized}>
								<StyledListItem>
									<NavLink to={`/gameplay`} onClick={handleClickLink}>
										{i18n.t("header.nav.gameplay", "Game Page")}
									</NavLink>
								</StyledListItem>
								<StyledListItem>
									<StyledDivider />
								</StyledListItem>
							</Exist>
							{/*<StyledListItem>*/}
							{/*	<NavLink to={`/help`} onClick={handleClickLink}>*/}
							{/*		{i18n.t("header.nav.help", "Help")}*/}
							{/*	</NavLink>*/}
							{/*</StyledListItem>*/}
							<StyledListItem>
								<NavLink to={`/help/faq's`} onClick={handleClickLink}>
									{i18n.t("header.nav.faq", "FAQ's")}
								</NavLink>
							</StyledListItem>
							<StyledListItem>
								<NavLink to={`/prizes`} onClick={handleClickLink}>
									{i18n.t("header.nav.prizes", "Prizes")}
								</NavLink>
							</StyledListItem>
							<StyledListItem>
								<NavLink to={`/tutorial`} onClick={handleClickLink}>
									{i18n.t("header.nav.tutorial", "Tutorial")}
								</NavLink>
							</StyledListItem>
							<Exist when={isAuthorized && IS_NOT_IFRAME}>
								<StyledListItem>
									<NavButton onClick={handleLogout}>
										{i18n.t("header.nav.logout", "Logout")}
									</NavButton>
								</StyledListItem>
							</Exist>
						</StyledList>
					</Box>
				</Box>
			</Drawer>
		</Root>
	);
});
