import {action, makeAutoObservable, observable} from "mobx";
import {ViewController} from "data/types/structure";
import {inject, injectable} from "inversify";
import {Bindings} from "data/constants/bindings";
import type {ILocalizationStore} from "data/stores/localization/localization.store";
import type {IModalsStore} from "data/stores/modals/modals.store";
import type {IUserStore} from "data/stores/user/user.store";
import {RequestState} from "data/enums";
import {AxiosError} from "axios";
import {IApiResponse} from "data/services/http";
import {extractErrorMessage} from "data/utils";
import {isEqual} from "lodash";
import {FormEvent} from "react";

export interface IModalRecoverUserController extends ViewController {
	readonly i18n: ILocalizationStore;
	get isOpen(): boolean;
	get errorMsg(): string | null;
	get terms(): boolean;
	get isLoading(): boolean;
	get isDisabled(): boolean;
	updateTerms: (event: FormEvent<HTMLInputElement>) => void;
	recover: () => void;
	close: () => void;
}

@injectable()
export class ModalRecoverUserController implements IModalRecoverUserController {
	@observable private _requestState = RequestState.IDLE;
	@observable private _errorMsg: string | null = null;
	@observable private _terms: boolean = false;

	constructor(
		@inject(Bindings.LocalizationStore) public readonly i18n: ILocalizationStore,
		@inject(Bindings.ModalsStore) private _modalsStore: IModalsStore,
		@inject(Bindings.UserStore) private _userStore: IUserStore
	) {
		makeAutoObservable(this);
	}

	get isOpen() {
		return Boolean(this._userStore.user && this._userStore.user.isRecovered === false);
	}

	get errorMsg() {
		return this._errorMsg;
	}

	get terms() {
		return this._terms;
	}

	get isLoading(): boolean {
		return isEqual(this._requestState, RequestState.PENDING);
	}

	get isDisabled() {
		return !this.terms || this.isLoading;
	}

	@action updateTerms = (event: FormEvent<HTMLInputElement>) => {
		this._terms = event.currentTarget.checked;
	};

	@action public recover = () => {
		if (!this.terms) {
			return;
		}

		this._requestState = RequestState.PENDING;
		this._userStore
			.recover()
			.then(() => {
				this._requestState = RequestState.SUCCESS;
			})
			.catch(this.onError);
	};

	@action private onError = (error: AxiosError<IApiResponse>) => {
		this._errorMsg = extractErrorMessage(error);
		this._requestState = RequestState.ERROR;
	};

	public close = () => {
		this._modalsStore.hideModal();
	};
}
