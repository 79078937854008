/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable @typescript-eslint/no-empty-interface */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable sonarjs/no-duplicate-string */

/*
 * Disabled the following rules:
 * @typescript-eslint/naming-convention
 * @typescript-eslint/no-empty-interface
 * @typescript-eslint/no-unused-vars
 * sonarjs/no-duplicate-string
 * as MUI theme override required override interfaces.
 * Also, it has no sense to move out css variables into another variable
 *
 * More details on style overrides here https://mui.com/material-ui/customization/theme-components/#default-props
 */
import {ThemeOptions} from "@mui/material/styles";
import {CSSInterpolation} from "@mui/material";

declare module "@mui/material/Button" {
	// allows to rewrite theme options

	// eslint-disable-next-line @typescript-eslint/naming-convention
	interface ButtonPropsColorOverrides {
		apple: true;
		primaryButton: true;
		secondaryButton: true;
	}

	// eslint-disable-next-line @typescript-eslint/naming-convention
	interface ButtonClasses {
		textApple: string;
		textPrimaryButton: string;
	}

	// eslint-disable-next-line @typescript-eslint/naming-convention
	interface ButtonPropsVariantOverrides {
		contained: true;
		primary: true;
		secondary: true;
	}
}

const primaryButtonHoverState: CSSInterpolation = {
	background: "#d32b3c",
};

const primaryStyle: CSSInterpolation = {
	height: "40px",
	padding: "6px 13px",
	fontSize: "14px",
	fontWeight: 800,
	lineHeight: "160%",
	color: "var(--text-light)",
	textTransform: "uppercase",
	borderRadius: "30px",
	background: "var(--primary-maroon)",
	":hover": primaryButtonHoverState,
	":active": primaryButtonHoverState,
	":disabled": {
		color: "#b21e30",
		background: "#fbd0d1",
	},

	"@media screen and (max-width: 768px)": {
		fontSize: "12px",
		height: "32px",
		textTransform: "none",
		padding: "6px 8px",
	},
};

export const buttonsTheme: ThemeOptions = {
	components: {
		MuiButton: {
			styleOverrides: {
				root: {
					fontFamily: "var(--fontFamilyBase)",
				},
			},
			variants: [
				{
					props: {variant: "primary"},
					style: primaryStyle,
				},
				{
					props: {variant: "primary", size: "large"},
					style: {
						...primaryStyle,
						height: "44px",
					},
				},

				// {
				// 	props: {className: "byClassName"},
				// 	style: {
				// 		background: "yellow",
				// 		color: "#000",
				// 		":hover": {
				// 			background: "blue",
				// 		},
				// 	},
				// },
			],
		},
	},
};
