import {inject, injectable} from "inversify";
import type {IUser} from "data/stores/user/user.store";
import type {IApiResponse, IHttpClientService} from "data/services/http";
import type {AxiosResponse} from "axios";
import {Bindings} from "data/constants/bindings";

export interface IRegistrationPayload {
	token: string;
	terms: boolean;
}

export interface IUpdateUserPayload extends Omit<IRegistrationPayload, "password"> {
	isNotificationsEnabled: boolean;
}

export interface IUsername {
	username: string;
}

export interface IRecoverUserPayload {
	terms: boolean;
}

export type TUserResponse = IApiResponse<{user: IUser}>;

export interface IUserApiProvider {
	update: (params: IUpdateUserPayload) => Promise<AxiosResponse<TUserResponse>>;
	user: () => Promise<AxiosResponse<TUserResponse>>;
	checkUsername: (params: IUsername) => Promise<AxiosResponse>;
	deactivateAccount: () => Promise<AxiosResponse>;
	readTutorial: () => Promise<AxiosResponse>;
	recover: (params: IRecoverUserPayload) => Promise<AxiosResponse<TUserResponse>>;
}

@injectable()
export class UserApiProvider implements IUserApiProvider {
	constructor(@inject(Bindings.ApiHTTPClient) private _http: IHttpClientService) {}

	checkUsername = (params: IUsername) => this._http.post<void>("user/check_username", params);

	deactivateAccount = () => this._http.post<void>("user/deactivate_account");

	update = (params: IUpdateUserPayload) => this._http.post<TUserResponse>("user/update", params);

	user = () => this._http.get<TUserResponse>("user");

	readTutorial = () => this._http.post<void>("user/tutorial");

	recover = (params: IRecoverUserPayload) =>
		this._http.post<TUserResponse>("user/recover", params);
}
